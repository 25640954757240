.app { 
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}
